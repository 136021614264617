//----------------------------------------------------//
// TYPOGRAPHY                                         //
//----------------------------------------------------//
html {
  font-size: 16px;
}

body {
  background: #fff;
  color: $black;
  font-family: $main_font, $fix_font;
  @include font-size(14px);
  font-weight: 400;
  line-height: $main_line_height;
}

h1, h2, h3, h4, h5, h6 {
  color: $black;
  font-family: "HelveticaNeueCyr-Bold", $fix_font;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 1.2;
  text-align: center;
}

h1 {
  color: $white;
  font-size: 34px;
  margin: 20px 0;
  @include font-size(34px);

  @include media-breakpoint-only(xs){
    @include font-size(26px)
  }
}

h2 {
  @include font-size(30px);
  margin: 0 0 70px;
  @include media-breakpoint-down(sm){
    margin: 0 0 40px;
  }
  span{
    color: $green;
  }
  @include media-breakpoint-only(xs){
    @include font-size(24px)
  }
}

h3 {
  @include font-size(28px);
  @include media-breakpoint-only(xs){
    @include font-size(22px)
  }

}

h4 {
  @include font-size(24px);
  @include media-breakpoint-only(xs){
    @include font-size(20px)
  }

}

h5 {
  @include font-size(22px);
  @include media-breakpoint-only(xs){
    @include font-size(18px)
  }

}

h6 {
  @include font-size(16px)

}

p {
  padding: 0;
  margin: 0;
  @include font-size(14px);
}

hr {
  overflow: hidden;
  height: 1px;
  background: $black;
  border: 0;
  margin: 10px 0;
}

q,
blockquote {
  text-align: center;
  display: block;
  font-style: italic;
}

strong,
b {
  font-weight: 700;
}

a {
  color: $white;
  transition: $main_animation;
  text-decoration: none;
  &.button {
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }
}

a:hover {
  text-decoration: none;
  color: $black;
}

a:active {
}

a:visited {
}

ul {
  li {
    padding-bottom: 4px;
  }
}


ol {
  list-style-type: decimal;
  list-style-position: inside;
  counter-reset: item;
  li {
    display: block;
    position: relative;
    &:before {
      content: counters(item, ".") ". ";
      counter-increment: item;
      display: inline;
      vertical-align: top;
      font-weight: 700;
    }
  }
}

.upper {
  text-transform: uppercase;
}

