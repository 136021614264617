//----------------------------------------------------//
// FONTS                                              //
//----------------------------------------------------//

/*HelveticaNeueCyr FAMILY*/

@font-face {
  font-family: 'HelveticaNeueCyr-Roman';
  src: local('HelveticaNeueCyr-Roman'),
  url('../fonts/HelveticaNeueCyr-Roman.woff2') format('woff2'),
  url('../fonts/HelveticaNeueCyr-Roman.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeueCyr-Bold';
  src: local('HelveticaNeueCyr-Bold'),
  url('../fonts/HelveticaNeueCyr-Bold.woff2') format('woff2'),
  url('../fonts/HelveticaNeueCyr-Bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeueCyr-Light';
  src: local('HelveticaNeueCyr-Light'),
  url('../fonts/HelveticaNeueCyr-Light.woff2') format('woff2'),
  url('../fonts/HelveticaNeueCyr-Light.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeueCyr-Medium';
  src: local('HelveticaNeueCyr-Medium'),
  url('../fonts/HelveticaNeueCyr-Medium.woff2') format('woff2'),
  url('../fonts/HelveticaNeueCyr-Medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

/*OpenSans FAMILY*/
@font-face {
  font-family: 'OpenSans';
  src: local('Open Sans'), local('OpenSans'),
  url('../fonts/OpenSans.woff2') format('woff2'),
  url('../fonts/OpenSans.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}