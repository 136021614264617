/*Fonts*/

$main_font: "HelveticaNeueCyr-Light";
$fix_font: Arial, sans-serif;
$main_line_height: 1.5;

/*Animations*/

$main_animation: all .4s ease-in-out;

/*Colors*/

$black: #383838;
$white: #ffffff;
$olive: #626c3f;
$green: #2ea55a;
$gray: #b7b7b7;
$light-gray: #f7f7f7;