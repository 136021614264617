@import 'sprite',
'../lib/normalize-css/normalize.css',
'bootstrap/bootstrap-grid',
'variables',
'fonts',
'mixins&includes',
'typography',
'forms',
'layout',
'hamburgers/hamburgers',
'../lib/fancybox/dist/jquery.fancybox.min.css',
'../lib/slick-carousel/slick/slick.css',
'../lib/animate.css/animate.min.css';

//----------------------------------------------------//
// MAIN 						 				      //
//----------------------------------------------------//

.animated {
  opacity: 0;
  animation-duration: 1.2s;
}

.hero {
  min-height: 745px;
  padding: 72px 0 0 0;
  background: $olive url('../img/hero_bg.jpg') no-repeat center center;
  -webkit-background-size: cover;
  background-size: cover;
  position: relative;
  &:before {
    content: '';
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    background: rgba(#000000, .4);
  }
  &__content {
    padding: 120px 0 95px;
    @include media-breakpoint-down(sm) {
      padding: 40px 0;
    }
  }
  &__btn {
    max-width: 320px;
    margin: 45px auto 0;
    animation-delay: .6s;
  }
}

.heading {
  text-align: center;
  color: $white;

  &__excerpt {
    @include font-size(22px);
  }

  &__description {
    @include font-size(16px);
    line-height: 21px;
  }
}

.benefits {
  display: flex;
  justify-content: center;
  margin: 100px 0 0;
  animation-delay: .9s;
  @include media-breakpoint-down(xs) {
    flex-direction: column;
    align-items: center;
  }

  &__item {
    flex-basis: 263px;
    border-right: 1px solid $white;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $white;
    padding: 25px 10px 18px;
    @include media-breakpoint-down(xs) {
      flex-basis: 150px;
      width: 100%;
      max-width: 205px;
      border-left: 1px solid $white;
      margin-bottom: 40px;

    }

    svg {
      width: 51px;
      height: 51px;
      fill: $white;
    }

    span {
      display: inline-block;
      margin: 25px 0 0;
    }

    &:first-of-type {
      border-left: 1px solid $white;
    }

  }
}

.portfolio {
  padding: 80px 0 0;
  &__list {
    display: flex;
    flex-wrap: wrap;

    @include media-breakpoint-down(sm) {
      justify-content: center;
    }
  }

  &__item {
    position: relative;
    font-size: 0;
    overflow: hidden;
    margin: 2px;
    flex-basis: calc(33.333333% - 4px);
    &:hover {
      img {
        transform: scale(1.1);
      }

      .portfolio__link {
        &:before {
          background: rgba(#000000, .2);
        }
      }
    }

    @include media-breakpoint-down(sm) {
      flex-basis: calc(50% - 4px);
    }

    @include media-breakpoint-only(xs) {
      flex-basis: calc(100% - 4px);
    }

    img {
      transition: all .8s ease-in-out;
      width: 100%;
      height: 100%;
      will-change: transform;
    }
    .portfolio__img {
      display: none;
      &:first-of-type {
        display: block;
      }
    }
  }

  &__link {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    font-family: "HelveticaNeueCyr-Bold", $fix_font;
    font-weight: 700;
    @include font-size(28px);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    text-transform: uppercase;
    color: $white;
    @media (max-width: 1350px) {
      @include font-size(24px);
    }
    &:before {
      content: '';
      position: absolute;
      width: 87%;
      height: 87%;
      background: rgba(#000000, .4);
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;
      transition: $main_animation;
    }
    &:hover {
      color: $white;
    }
    @include media-breakpoint-down(lg) {
      @include font-size(22px);
    }
    @include media-breakpoint-down(md) {
      @include font-size(16px);
    }
  }

  &__btn {
    max-width: 385px;
    margin: 80px auto 0;
  }
}

.clients {
  padding: 0 0 80px;
  &__img {
    height: 80px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      max-height: 100%;
      max-width: 180px;
    }
  }

  &__slider {
    &-nav {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 75px;

      @include media-breakpoint-only(xs) {
        margin: 15px 0 0;
      }
      .prev,
      .next {
        cursor: pointer;
        line-height: 1;

        @include media-breakpoint-only(xs) {
          margin: 0 25px;
        }
        svg {
          width: 10px;
          height: 18px;
        }
      }

      .slick-dots {
        padding: 0;
        margin: 0 30px;
        list-style-type: none;
        display: flex;
        align-items: center;
        width: 480px;

        @include media-breakpoint-only(xs) {

        }

        li {
          width: 100%;
          transition: $main_animation;
          &.slick-active {
            button {
              background: $green;
              height: 3px;
              border-color: $green;
              position: relative;
            }
          }

          button {
            font-size: 0;
            padding: 0;
            line-height: 0;
            display: block;
            background: $gray;
            height: 1px;
            border: none;
          }
        }
      }
    }
  }
}

.stages {

  .col {
    display: flex;

    &:nth-of-type(even){
      .stages__item{
        @include media-breakpoint-down(sm){
          flex-direction: column-reverse;
        }
      }
    }

    &:first-of-type {

      .stages {

        &__arrow {
          right: 35%;
          bottom: -10px;
        }
      }
    }

    &:nth-of-type(2) {

      .stages {

        &__item {
          padding: 45px 0 0;
          @include media-breakpoint-down(md) {
            padding: 0;
          }
        }

        &__arrow {
          bottom: 0;
          left: 35%;
        }
      }
    }

    &:nth-of-type(3) {

      .stages {

        &__item {
          justify-content: flex-end;
          @include media-breakpoint-down(md) {
            justify-content: flex-start;
          }
        }

        &__arrow {
          left: 50%;
          top: 87%;
        }
      }
    }

    &:last-of-type {

      .stages__item {
        padding: 45px 0 0;
        justify-content: flex-end;
        @include media-breakpoint-down(md) {
          padding: 0;
          justify-content: flex-start;
        }
      }
    }
  }

  &__item {
    display: flex;
    align-items: center;
    position: relative;


    @include media-breakpoint-down(md) {
      margin: 0 0 40px;
    }

    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }

  }

  &__img {
    max-width: 340px;
    overflow: hidden;
    margin: 0 25px 0 0;
    flex: none;
    img {
      width: 100%;
      height: auto;
    }
  }

  &__text {
    //flex-basis: 260px;

    h6 {
      text-align: left;
      margin: 0 0 12px;
      text-transform: none;
      @include media-breakpoint-only(xs) {
        text-align: center;
      }
    }

    @include media-breakpoint-only(xs) {
      flex-basis: auto;
      text-align: center;
    }
  }

  &__arrow {
    position: absolute;
    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  &__btn {
    max-width: 385px;
    margin: 60px auto 0;
  }
  &__short-text{
    max-width: 930px;
    margin: 0 auto;
    padding-top: 30px;
    p{
      color: $black;
      @include font-size(16px);
      font-weight: 300;
    }
  }
}

.team {
  padding-top: 0;
  &__item {
    position: relative;
    font-size: 0;
    overflow: hidden;
    @include media-breakpoint-down(md) {
      margin: 0 0 30px 0;
    }
    &:hover {
      .team__text {
        transform: translate(0, 0);
      }
    }
  }
  &__photo {
    img {
      max-width: 100%;
      height: auto;
    }
  }
  &__text {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: rgba($olive, .86);
    @include font-size(14px);
    padding: 7px;
    color: $white;
    transform: translate(0, 100%);
    transition: all ease-in-out .4s;
  }
  &__position {
    font-weight: 700;
  }
}

.callback {
  background: $light-gray;
  padding: 27px 0 0;

  @include media-breakpoint-down(md) {
    padding: 27px 0;
  }

  &__wrapper {
    display: flex;
    align-items: center;
  }

  &__img {
    font-size: 0;
    margin: 0 35px 0 0;

    @include media-breakpoint-only(xs) {
      display: none;
    }
  }

  &__heading {
    margin: 0 0 25px 0;

    h3 {
      text-align: left;
      margin: 0 0 18px;
    }

    span {
      @include font-size(18px);
    }
  }

  &__form {
    flex-grow: 1;
  }

  form {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    @include media-breakpoint-down(md) {
      flex-wrap: wrap;
      justify-content: center;
    }

    input {
      flex-basis: 270px;
      margin: 0 40px 0 0;
      @include media-breakpoint-down(md) {
        margin: 0 0 40px;
        flex-basis: 100%;
      }
    }
    .btn {
      margin: 0;
      max-width: 235px;
    }
  }

  &__popup {
    max-width: 533px;
    padding: 15px;
    text-align: center;

    .callback {
      &__form {
        padding: 60px 35px;
        border: 1px solid $green;
      }

      &__heading {
        margin: 0 0 40px;
      }

      &__row {
        margin: 0;
      }
    }

    h3 {
      text-align: center;
    }

    .btn {
      max-width: 234px;
      margin: 85px auto 0;
    }
  }

  &__row {
    flex-basis: 100%;
    margin: 0 40px 0 0;
    position: relative;
  }
}

.fancybox-close-small {
  &:after {
    background: $white;
  }
  &:hover {
    background: transparent;
    border: 0;

  }
}

.contacts {
  min-height: 580px;
  position: relative;
  &__map {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1;
  }

  &__wrapper {
    max-width: 500px;
    box-shadow: 0 0 49px rgba(61, 61, 62, 0.36);
    background: rgba(#000000, .6);
    min-height: 480px;
    padding: 70px;
    position: relative;
    z-index: 1;

    @include media-breakpoint-down(sm) {
      max-width: none;
      padding: 70px 20px;
    }

    h2 {
      color: $white;
      text-align: left;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    margin: 0 0 45px 0;
    &:last-of-type {
      margin: 0;
    }

    h6 {
      text-align: left;
      color: $white;
      text-transform: none;
      margin: 0 0 10px 0;
    }

    a, span {
      display: inline-block;
      color: $white;
      &:hover {
        color: $white;
      }
    }
  }

  &__icon {
    margin: 0 35px 0 0;
    svg {
      width: 23px;
      height: 33px;
      fill: $white;
    }
  }

  &__text {
    &--nowrap {
      display: flex;
      align-items: center;
      h6 {
        margin: 0 5px 0 0;
      }
    }
  }
}

.response {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999999;
  left: 0;
  top: 0;
  align-items: center;
  justify-content: center;
  background: rgba(#1e1e1e, .87);
  display: none;

  &__wrapper {
    width: 100%;
    max-width: 533px;
    background: $white;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 15px;
    display: flex;
    align-items: stretch;
    justify-content: center;
  }

  &__text {
    color: $black;
    width: 100%;
    min-height: 242px;
    border: 1px solid $green;
    display: flex;
    justify-content: center;
    align-items: center;
    @include font-size(22px);
    font-weight: 700;
    font-family: "HelveticaNeueCyr-Bold", $fix_font;
    text-align: center;
    @include media-breakpoint-only(xs) {
      font-size: 16px;
    }
  }
}

.oasis {
  padding-bottom: 0;
  &__img {
    img {
      height: 100%;
      width: auto;
    }
  }
  &__descr {
    margin-bottom: 34px;
    @include media-breakpoint-down(sm){
      text-align: center;
    }
    p {
      @include font-size(16px);
      margin-bottom: 23px;
      line-height: 21px;
    }
  }

  &__list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    &-item {
      flex-basis: 50%;
      display: flex;
      margin-bottom: 16px;
      @include media-breakpoint-down(md){
        flex-basis: 100%;
      }
    }
    &-icon {
      width: 78px;
      height: 78px;
      background: $green;
      flex: none;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 20px;
    }
    &-excerpt {
      display: flex;
      flex-direction: column;
      color: $black;
      @include font-size(14px);
      font-weight: 300;
      line-height: 21px;
      padding-top: 30px;
    }
    &-heading {
      color: $black;
      @include font-size(16px);
      font-weight: 700;
    }
  }
}

/*LISTS and TYPICAL ELEMENTS*/

/*end LISTS and TYPICAL ELEMENTS*/

/*main styles*/