//----------------------------------------------------//
// LAYOUT                                             //
//----------------------------------------------------//
*,
*:before,
*:after {
  box-sizing: border-box;
  outline: none;
}
html{
  height: 100%;
}

body {
  height: 100%;
  min-width: 320px;
  display: flex;
  flex-direction: column;
}

//sticky footer
.fixcontent{
  flex: 1 0 auto;
}


//----------------------------------------------------//
// HEADER                                             //
//----------------------------------------------------//

.header{
  background: rgba($olive, .86);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  position: fixed;
  z-index: 100;
  width: 100%;
  top: 0;
  left: 0;

  &__content{
    display: flex;
    align-items: center;

  }

  &__contacts{
    display: flex;
    align-items: center;
    margin: 0 0 0 auto;
    height: 100%;
    justify-content: flex-end;

    svg{
      width: 18px;
      height: 33px;
      fill: $white;
    }
  }

  &__phones{
    display: flex;
    flex-direction: column;
    margin: 0 0 0 10px;
    padding: 15px 0;
    a{
      @include font-size(14px);
    }
  }

  a{
    font-family: "HelveticaNeueCyr-Roman", $fix_font;
    font-weight: 400;
    &:hover{
      color: $white;
    }
  }

  &.sticky{

    .logo{
      width: 80px;
      padding: 4px 0;
      @include media-breakpoint-down(md){
        padding: 7px 0 4px;
      }
    }

    .hamburger{
      padding: 9px 15px 7px 0;;
    }

    .header__phones{
      padding: 5px 0;

    }
  }

}

.nav{
  width: 100%;
  height: 100%;

  &--mobile{
    position: absolute;
    width: 100%;
    left: 0;
    top: 100%;
    background: rgba($olive, .86);
    padding: 30px 0 15px;
    display: none;
    .menu{
      flex-direction: column;
      width: 100%;
      align-items: center;
      li{
        margin: 0 0 15px;
      }
    }

    @include media-breakpoint-up(lg){
      display: none;
    }

  }

}

.logo{
  width: 105px;
  padding: 8px 0;
  transition: $main_animation;
  @include media-breakpoint-down(md){
    margin: 0 0 0 auto;
  }

  img{
    max-width: 100%;
  }
}

.menu{
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  height: 100%;

  li{
    display: flex;
    align-items: center;
    padding: 0;
    border-bottom: 1px solid transparent;
    transition: $main_animation;
    &:hover{
      border-bottom-color: $white;
    }
  }

  a{
    padding: 0 25px;
    @include font-size(13px);
    text-transform: uppercase;
    height: 100%;
    display: flex;
    align-items: center;
    @include media-breakpoint-down(lg){
      padding: 0 20px;
    }
  }
}

section{
  padding: 85px 0;
}
//----------------------------------------------------//
// SIDEBAR                                            //
//----------------------------------------------------//



//----------------------------------------------------//
// FOOTER                                             //
//----------------------------------------------------//

.footer {
  background: $olive;
  &__wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 25px 0;

    @include media-breakpoint-down(sm){
      flex-direction: column;
    }
  }
}

.social{
  display: flex;
  align-items: center;

  @include media-breakpoint-down(sm){
    margin: 20px 0;
  }

  &__item{
    border: 1px solid $black;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 8px 0 0;
    transition: $main_animation;
    &:last-of-type{
      margin: 0;
    }
    &:hover{
      transform: scale(1.1);
    }

    svg{
      width: 25px;
      height: 16px;
      fill: $black;
    }
  }
}

.copyright{
  @include font-size(12px);
  text-transform: uppercase;
  font-family: "OpenSans", $fix_font;
  font-weight: 400;
  color: $white;
  &:hover{
    color: $white;
  }
}
